import React from "react"
import Topic from "../../components/Topic"

const Education = () => (
  <Topic id="education">
    <p>
      Education for children is a high priority for every parent. Canada
      provides free education for kindergarten through grade twelve (usually
      from four years old through eighteen years old). Preschools are available
      for younger children but usually you must pay for this.
    </p>
    <p>
      You have the option of sending your child to the Public School system or
      the Separate (Catholic) School system. You also have the option of sending
      your child to school in French in some provinces (including Ontario).
      Depending on the language you speak at home, and your religious
      background/preference your child can attend either English Public, English
      Catholic, French Public or French Catholic school.
    </p>
    <p>
      Canada&apos;s education system emphasizes academic excellence in an open
      environment where children are encouraged to express themselves. While
      Canadian schools are generally very good, the quality of individual
      schools varies from community to community. This section includes
      information about Canada&apos;s educational system and directions for
      exploring the school systems in your new neighbourhood. You will also find
      information on post-secondary education, adult education and career
      training.
    </p>
  </Topic>
)

export default Education
